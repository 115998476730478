import React, { useState, useEffect } from "react";
import {
  onValue,
  ref,
  query,
  orderByKey,
  limitToLast,
} from "firebase/database";
import { database } from "../../firebase";
import NotificationStyles from "../../assets/styles/notification.module.css";
import TimelineItem from "../../components/dashboard/widgets/TimelineItem";
import { showError } from "../../utils/Alert";
import NotiIcon from "../../assets/images/icons/notification.png";
import WalletAdd from "../../assets/images/icons/wallet-add.png";
import WalletMinus from "../../assets/images/icons/wallet-minus.png";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import useTextTruncate from "../../hooks/useTextTruncate";

export const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const { data: user } = useFetchProfileQuery();
  const { truncateText } = useTextTruncate();

  // fetch messages
  useEffect(() => {
    const notifRef = ref(database, `notifications/users/${user?.uid}`);
    const notifQuery = query(notifRef, orderByKey(), limitToLast(10)); // Limiting to last 10

    const unsubscribe = onValue(
      notifQuery,
      (snapshot) => {
        if (snapshot.exists()) {
          const notificationData = snapshot.val();
          const filteredTransactions = Object.keys(notificationData)
            .map((key) => {
              const { subject, body, topic, timestamp, message } =
                notificationData[key] || {};
              return {
                id: key,
                title: subject,
                body,
                message,
                type: topic,
                time: timestamp, // Convert time to Date object
              };
            })
            .sort((a, b) => {
              if (!a.time && !b.time) return 0;
              if (!a.time) return 1;
              if (!b.time) return -1;

              const dateA = new Date(a.time);
              const dateB = new Date(b.time);

              return dateB - dateA;
            });
          setNotifications(filteredTransactions);
        }
      },
      (error) => {
        console.error(error);
        showError("Could not fetch notifications");
      }
    );

    return () => unsubscribe();
  }, [user]);

  return (
    <>
      <section className="mainContainer no-aside">
        <div className={`mainContent`}>
          <div>
            <div className="flexRow justifyBetween">
              <h3>Notifications</h3>
            </div>
            <div>
              {/* <h3 className={NotificationStyles.dateHeader}>August, 2024</h3> */}
              <div className={NotificationStyles.timeline}>
                {notifications.map((val, idx) => {
                  let iconBgColor = "#FFD600";
                  let icon = NotiIcon;
                  let newDate = new Date(val?.time);
                  let newTime = new Date(val?.time);
                  // @ts-ignore
                  newDate = newDate.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  });

                  const time = newTime.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });

                  if (
                    val?.type?.toLowerCase() === "deposit" ||
                    val?.type?.toLowerCase() === "jackpot win"
                  ) {
                    iconBgColor = "#FFF7CC";
                    icon = WalletAdd;
                  }

                  if (
                    val?.type?.toLowerCase() === "withdraw" ||
                    val?.type?.toLowerCase() === "ticket sale"
                  ) {
                    iconBgColor = "#ffe1de4d";
                    icon = WalletMinus;
                  }

                  return (
                    <div
                      key={val?.id}
                      className={`${NotificationStyles.event} ${
                        idx % 2 === 0
                          ? NotificationStyles.left
                          : NotificationStyles.right
                      }`}
                    >
                      {/* @ts-ignore */}
                      <div className={NotificationStyles.eventDate}>
                        {newDate}
                      </div>
                      <TimelineItem
                        icon={icon}
                        iconBgColor={iconBgColor}
                        message={truncateText(val?.message, 35)}
                        time={time}
                      />
                    </div>
                  );
                })}
              </div>
              {!notifications?.length ? (
                <p
                  style={{
                    textAlign: "center",
                    color: "#868686",
                    marginBlock: "80px",
                  }}
                >
                  You have no new notification
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
