import React, { useEffect, useState } from "react";
import Text from "../../utils/CustomText";
import ContactCard from "../../components/dashboard/cards/ContactCard";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import useCopyToClipBoard from "../../hooks/useCopyToClipboard";
import { LuCopy } from "react-icons/lu";
import { AiOutlineCheck } from "react-icons/ai";
import Modal from "../../utils/Modal";
import { useFetchWalletBalanceQuery } from "../../redux/services/walletApi";
import {
  useFetchProfileQuery,
  useFetchReferralsQuery,
  useRegisterAffiliateMutation,
} from "../../redux/services/accountApi";
import NameTagContainer from "../../utils/NameTagContainer";
import CustomButtonII from "../../utils/CustomButtonII";
import CustomInput from "../../utils/CustomInput";
import { showError, showSuccess } from "../../utils/Alert";

const Affiliate = () => {
  const navigate = useNavigate();
  const [refWallet, setRefWallet] = useState(null);
  const [isRefModalOpen, setIsRefModalOpen] = useState(false);
  const [isMyRefModalOpen, setIsMyRefModalOpen] = useState(false);
  const [myRefCode, setMyRefCode] = useState("");
  const { handleCopyClick, isCopied } = useCopyToClipBoard();
  const { data: user } = useFetchProfileQuery();
  const { data: referrals } = useFetchReferralsQuery();
  console.log(referrals);

  const { data: walletBalance, isSuccess: isWalletBalanceSuccess } =
    useFetchWalletBalanceQuery();

  useEffect(() => {
    if (isWalletBalanceSuccess) {
      const refWallet = walletBalance?.filter(
        (val) => val?.type?.toLowerCase() === "affiliate"
      );

      setRefWallet(refWallet[0]);
    }
  }, [isWalletBalanceSuccess, walletBalance]);

  const goBack = () => {
    navigate(-1);
  };

  const [registerAffiliate, { isLoading: isRegisterAffiliateLoading }] =
    useRegisterAffiliateMutation();
  const registerPromoCode = () => {
    if (!myRefCode) {
      showError("Enter referrer's code");
      return;
    }

    registerAffiliate({ referral_code: myRefCode })
      .unwrap()
      .then((resp) => {
        if (resp?.success) {
          showSuccess("success");
          setIsMyRefModalOpen(false);
        } else {
          showError("something went wrong, try again later");
        }
      })
      .catch((err) => {
        console.log(err);
        showError(err?.data?.message || err?.message || "An error occurred");
      });
  };

  return (
    <>
      <section className="mainContainer">
        <div className="content">
          <div className="settingHeader">
            <IoIosArrowRoundBack
              size={34}
              className={"arrow-back"}
              onClick={goBack}
            />
            <div className="headerTitle text-center">
              Affiliate and referrals
            </div>
          </div>

          <div className="affiliateContent">
            <h3 className="sub-head">Refer a friend bonus🎉</h3>
            <p>
              Introduce your friends to Lo3re and get bonuses and exclusive
              perks!
            </p>
            <div className="ref-code-box">
              <p>{user?.user?.referral_code || "-"}</p>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleCopyClick(user?.user?.referral_code || "-")
                }
              >
                {isCopied ? <AiOutlineCheck color="green" /> : <LuCopy />}
              </div>
            </div>
            <div className="ref-box">
              <div className="stat">
                <p className="figure">{`${refWallet?.balance || 0} TKN`}</p>
                <p className="text">Referral commissions</p>
              </div>
              <div className="stat">
                <p className="figure">{referrals?.length}</p>
                <p className="text">Friends referred</p>
              </div>
              <button
                className="btn-primary"
                onClick={() => setIsRefModalOpen(true)}
              >
                View list
              </button>
              <CustomButtonII
                variant={"ghost"}
                text={"My referrer"}
                onClick={() => setIsMyRefModalOpen(true)}
                centerText={true}
              />
            </div>
          </div>
        </div>

        {/* aside */}
        <aside className={"asideViewContainer"}>
          {/* <CardSlider /> */}
          <div className={"contactCornerContainer"}>
            <Text tag={"p"} className={"f16 satoshi-bold-text"}>
              Customer corner
            </Text>
            <ContactCard />
          </div>
        </aside>

        {/* modals */}
        <Modal
          title={"Friends referred"}
          isOpen={isRefModalOpen}
          onClose={() => setIsRefModalOpen(false)}
        >
          <div className="follower-list">
            {!referrals?.length ? (
              <p
                style={{ marginBlock: "50px" }}
                className={"textMuted textCenter"}
              >
                You don't have any referrals yet
              </p>
            ) : (
              ""
            )}
            {referrals?.map((value, idx) => (
              <NameTagContainer
                key={`refFs-${idx}`}
                name={value?.username}
                photo={value?.photoUrl}
              />
            ))}
          </div>
        </Modal>

        <Modal
          isOpen={isMyRefModalOpen}
          onClose={() => setIsMyRefModalOpen(false)}
        >
          <CustomInput
            label={"Enter your referrer's code"}
            value={myRefCode}
            onChange={({ target }) => setMyRefCode(target.value)}
            placeholder={"Enter code"}
          />

          <CustomButtonII
            variant={"primary"}
            text={"Submit"}
            style={{ width: "100%" }}
            onClick={registerPromoCode}
            centerText={true}
            isLoading={isRegisterAffiliateLoading}
          />
        </Modal>
      </section>
    </>
  );
};

export default Affiliate;
