import React, { useState } from "react";
import Text from "../../../utils/CustomText";
import { SlArrowRight } from "react-icons/sl";
import { PiChatCenteredDotsLight } from "react-icons/pi";
import cardStyles from "../../../assets/styles/cardStyles.module.css";
import Modal from "../../../utils/Modal";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
import {
  FaSquareInstagram,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";

const ContactCard = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  return (
    <>
      <div
        className={`${cardStyles.contactCard}`}
        onClick={() => setIsContactModalOpen(true)}
        style={{ cursor: "pointer" }}
      >
        <div className={`${cardStyles.contactCardIconContainer}`}>
          <PiChatCenteredDotsLight size={22} />
        </div>
        <div>
          <Text
            tag={"p"}
            className={"satoshi-medium-text f16"}
            style={{ color: "#000" }}
          >
            Contact us
          </Text>
          <Text
            tag={"p"}
            className={"satoshi-text f14"}
            style={{ color: "#000" }}
          >
            Chat with a member of our customer support team.
          </Text>
        </div>
        <SlArrowRight />
      </div>

      {/* modals */}
      <Modal
        title={"Contact us"}
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      >
        <div className="flexRow" style={{ gap: "20px" }}>
          <a
            href="https://www.facebook.com/profile.php?id=61552983155166"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaFacebook className="social-icons" color={"#106BFF"} />
          </a>
          <a
            href="https://www.linkedin.com/company/lo3re"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaLinkedinIn className="social-icons" color={"#0A66C2"} />
          </a>
          <a
            href="https://www.instagram.com/lo3rehq?igsh=NnY5czA2NzNzNzQz"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaSquareInstagram className="social-icons" color={"#E84636"} />
          </a>
          <a
            href="https://www.tiktok.com/@lo3rehq"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaTiktok className="social-icons" color={"#20141B"} />
          </a>
          <a
            href="https://whatsapp.com/channel/0029VaSt9iNI1rchf7iHIA2Z"
            target={"_blank"}
            rel="noreferrer"
          >
            <IoLogoWhatsapp className="social-icons" color={"#0CC143"} />
          </a>
          <a
            href="https://x.com/lo3rehq?t=S8BQn_gyQ5Gtms9HLlEpbw&s=09"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaXTwitter className="social-icons" color={"#000000"} />
          </a>
          <a
            href="https://www.youtube.com/@lo3re"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaYoutube className="social-icons" color={"#FF0000"} />
          </a>
        </div>
      </Modal>
    </>
  );
};

export default ContactCard;
