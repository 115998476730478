import React, { useEffect, useState } from "react";
import {
  Flex,
  Icon,
  Text,
  Box,
  Button,
  Image,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
} from "@chakra-ui/react";
import lotteryStyles from "../../assets/styles/lotteries.module.css";
import CustomButtonII from "../../utils/CustomButtonII";
import BgImage from "../../assets/images/default.png";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { IoChevronForward } from "react-icons/io5";
import { TiGroup } from "react-icons/ti";
import { FaFacebook, FaTelegram } from "react-icons/fa";
import { FaEllipsisH } from "react-icons/fa";
import MsgAdd from "../../assets/images/icons/message-add.png";
import { IoLogoWhatsapp } from "react-icons/io";
import BalanceCard from "../../components/dashboard/wallet/BalanceCard";
import Modal from "../../utils/Modal";
import WasteCollection from "../../assets/images/wastecollection.png";
import { DateTime } from "luxon";
import { GrLink } from "react-icons/gr";
import useTimeFormatter from "../../hooks/useTimeFormatter";
import {
  useDeleteGameMutation,
  useFetchGameLeaderBoardQuery,
  useFetchGameTicketsQuery,
} from "../../redux/services/gameApi";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import { showError, showSuccess } from "../../utils/Alert";
import useTextTruncate from "../../hooks/useTextTruncate";
import Loader from "../../utils/Loader";
import TicketSalesModal from "../../components/dashboard/widgets/TicketSalesModal";
import Avatar from "../../utils/Avatar";
import CustomCheckbox from "../../utils/CustomCheckbox";
import SelectionModal from "../../components/dashboard/widgets/SelectionModal";
import {
  onValue,
  ref,
  query,
  orderByKey,
  limitToLast,
  endAt,
  onChildAdded,
} from "firebase/database";
import { database } from "../../firebase";
import { IoIosNotifications } from "react-icons/io";
import { truncateText } from "../../utils/Helpers";
import { showInfo } from "../../utils/Alert";

function anyKeyHasValue(obj) {
  for (const key in obj) {
    if (obj[key] !== "") {
      return true;
    }
  }
  return false;
}

const Ranks = ["👑 King", "⚔️ Knight", "🧙🏽‍♂️ Mage"];

const ViewGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hideBackBtn = searchParams.get("hideBackBtn");
  const game = location.state?.game;
  const [gameNotifications, setGameNotifications] = useState([]);
  const [isGameNotificationsLoading, setIsGameNotificationsLoading] =
    useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { formatDateToLocaleString, formatDuration } = useTimeFormatter();
  const { truncateText, formatMoney, ensureHttps } = useTextTruncate();
  const { data: gameTickets, isLoading: isTicketsLoading } =
    useFetchGameTicketsQuery(game?.id);
  const [deleteGame, { isLoading: isDeleteGameLoading }] =
    useDeleteGameMutation();
  const {
    data: leaderboard,
    isSuccess: isLeaderBoardSuccess,
    isLoading: isLeaderboardLoading,
  } = useFetchGameLeaderBoardQuery(game?.id);
  const { data: user } = useFetchProfileQuery();
  const [sortedLeaderboards, setSortedLeaderboards] = useState([]);

  useEffect(() => {
    if (isLeaderBoardSuccess) {
      const ld = [...leaderboard?.leaderboards];
      ld?.sort((a, b) => a?.rank - b?.rank);
      setSortedLeaderboards(ld);
    }
  }, [isLeaderBoardSuccess, leaderboard?.leaderboards]);

  // fetch messages
  useEffect(() => {
    setIsGameNotificationsLoading(true);
    const notifRef = ref(database, `notifications/games/${game?.id}/general`);
    const notifQuery = query(notifRef, orderByKey(), limitToLast(10)); // Limiting to last 10

    const unsubscribe = onValue(
      notifQuery,
      (snapshot) => {
        setIsGameNotificationsLoading(false);
        if (snapshot.exists()) {
          const notificationData = snapshot.val();
          const filteredTransactions = Object.keys(notificationData)
            .map((key) => {
              const { type, timestamp, message } = notificationData[key] || {};
              return {
                id: key,
                message,
                type,
                time: timestamp, // Convert time to Date object
              };
            })
            .sort((a, b) => {
              if (!a.time && !b.time) return 0;
              if (!a.time) return 1;
              if (!b.time) return -1;

              const dateA = new Date(a.time);
              const dateB = new Date(b.time);

              // @ts-ignore
              return dateB - dateA;
            });
          setGameNotifications(filteredTransactions);
        }
      },
      (error) => {
        console.error(error);
        setIsGameNotificationsLoading(false);
        showError("Could not fetch notifications");
      }
    );

    return () => unsubscribe();
  }, [game?.id]);

  // listen for new node addition
  useEffect(() => {
    const notifRef = ref(database, `notifications/games/${game?.id}/general`);
    // @ts-ignore
    const q = query(notifRef, endAt(), limitToLast(1));

    onChildAdded(q, (data) => {
      const { message, timestamp } = data.val();
      const threeMin = 3 * 60 * 1000;

      const time = Date.parse(timestamp);
      if (time >= Date.now() - threeMin) {
        showInfo(message);
      }
    });
  }, [game?.id]);

  const handleDeleteGame = async () => {
    await deleteGame(game?.id)
      .unwrap()
      .then(() => {
        showSuccess("Game deleted");
        navigate(-1);
      })
      .catch((err) => {
        showError("An error occurred, try again later");
        console.log(err);
      });
  };

  return (
    <>
      <section
        className={`mainContainer w65-45 ${lotteryStyles.mainContainer}`}
      >
        <div className={`mainContent ${lotteryStyles.addLottery}`}>
          {!hideBackBtn ? (
            <IoIosArrowRoundBack
              size={34}
              className={"cursor-pointer"}
              onClick={() => navigate(-1)}
              style={{ marginBottom: "16px" }}
            />
          ) : (
            <IoIosArrowRoundBack
              size={34}
              className={"cursor-pointer"}
              onClick={() => navigate("/dashboard/lotteries")}
              style={{ marginBottom: "16px" }}
            />
          )}
          <div
            className={`flexRow alignCenter avatarProfileContainer ${lotteryStyles.avatarProfileContainer} ${lotteryStyles.viewGameAvatarContainer}`}
          >
            <div className={lotteryStyles.avatar}>
              <img
                src={game?.coverUrl || BgImage}
                alt=""
                className={lotteryStyles.fileImg}
              />
            </div>
            <div className={lotteryStyles.content}>
              <h3 className="title capitalize">{game?.title}</h3>
              <p className={`subtitle ${lotteryStyles.subtitle}`}>
                Jackpot prize: <b>${formatMoney(game?.jackpot)}</b>
              </p>
              <div
                className={`fs14 mediumText ${lotteryStyles.liveUserStatFlex}`}
              >
                <div
                  className="flexRow alignCenter"
                  style={{ gap: "8px", flexWrap: "wrap" }}
                >
                  <div className="flexRow alignCenter capitalize">
                    <GoDotFill className={`game-${game?.status}`} />
                    <span>
                      {game?.status === "active" ? "Live" : game?.status}
                    </span>
                  </div>
                  {formatDuration(game?.endOn) ? (
                    <p className={lotteryStyles.timerLg}>
                      {formatDuration(game?.endOn)} left
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flexRow alignCenter" style={{ gap: "4px" }}>
                  <TiGroup color="#2F53D7" fontSize={24} />
                  {/* <span className="text-muted fs14">1002/10,000 playing</span> */}
                  <span className="text-muted fs14">
                    {game?.totalPlayers} playing
                  </span>
                </div>
              </div>
            </div>
          </div>
          <form className={lotteryStyles.lotteryForm}>
            <div className={`flexRow justifyBetween ${lotteryStyles.col2}`}>
              <div className={`inputContainer ${lotteryStyles.inputContainer}`}>
                <label>Ticket price</label>
                <input
                  type="text"
                  className="formInput"
                  value={"$" + game?.ticketPrice}
                  readOnly
                  name="ticketPrice"
                />
              </div>
              <div className={`inputContainer ${lotteryStyles.inputContainer}`}>
                <label>Ticket goal</label>
                <input
                  type="text"
                  className="formInput"
                  value={`$${formatMoney(game?.ticketSales)}/$${formatMoney(
                    game?.ticketGoal
                  )}`}
                  readOnly
                  name="ticketCapacity"
                />
              </div>
            </div>

            <div className={`inputContainer ${lotteryStyles.inputContainer}`}>
              <label>Cause</label>
              <input
                type="text"
                className="formInput"
                value={game?.cause}
                readOnly
                name="cause"
              />
            </div>

            <div className={`inputContainer ${lotteryStyles.inputContainer}`}>
              <label>Description</label>
              <textarea
                className="formInput"
                value={game?.description}
                name="description"
                cols="30"
                rows="5"
                readOnly
              ></textarea>
            </div>

            <div style={{ marginBlock: "10px", marginBottom: "18px" }}>
              <CustomCheckbox
                isChecked={game?.infinite}
                label={"Run game indefinitely"}
                name={"infinite"}
                isReadOnly={true}
              />
            </div>

            <div className={`flexRow justifyBetween ${lotteryStyles.col2}`}>
              <div className={`inputContainer ${lotteryStyles.inputContainer}`}>
                <label>Lottery starts</label>
                <input
                  type="text"
                  className="formInput"
                  value={formatDateToLocaleString(game?.startOn)}
                  readOnly
                  name="ticketPrice"
                />
              </div>
              <div className={`inputContainer ${lotteryStyles.inputContainer}`}>
                <label>Lottery ends</label>
                <input
                  type="text"
                  className="formInput"
                  value={formatDateToLocaleString(game?.endOn)}
                  readOnly
                  name="ticketCapacity"
                />
              </div>
            </div>

            <div className={lotteryStyles.links}>
              {anyKeyHasValue(game?.socials) ? <p>Links</p> : ""}
              <div
                className="flexRow"
                style={{ gap: "10px", marginTop: "10px", fontSize: "27px" }}
              >
                {game?.socials?.telegram ? (
                  <a
                    href={ensureHttps(game?.socials?.telegram)}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaTelegram color="#28A8E9" />
                  </a>
                ) : (
                  ""
                )}
                {game?.socials?.facebook ? (
                  <a
                    href={ensureHttps(game?.socials?.facebook)}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaFacebook color="#0F8FF2" />
                  </a>
                ) : (
                  ""
                )}
                {game?.socials?.whatsapp ? (
                  <a
                    href={ensureHttps(game?.socials?.whatsapp)}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <IoLogoWhatsapp color="#2AB03F" />
                  </a>
                ) : (
                  ""
                )}
                {game?.socials?.others ? (
                  <a
                    href={ensureHttps(game?.socials?.others)}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLink />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              className={`${lotteryStyles.formButtonContainer} ${lotteryStyles.addGame}`}
            >
              <CustomButtonII
                variant={"ghost-danger"}
                text={"Delete"}
                className="btnLg me10"
                type="button"
                onClick={() => setShowDeleteModal(true)}
                centerText={true}
              />
              <CustomButtonII
                variant={"primary"}
                text={"Update"}
                className="btnLg"
                type="button"
                onClick={() =>
                  navigate("/dashboard/lotteries/update-game", {
                    state: { game },
                  })
                }
                centerText={true}
              />
            </div>
          </form>
        </div>

        {/* aside */}
        <aside
          className={`asideViewContainer ${lotteryStyles.asideViewContainer}`}
        >
          <div className={`cardContainer ${lotteryStyles.asideCardContainer}`}>
            <BalanceCard
              title={"Tickets sold"}
              figure={game?.ticketSalesCount}
              subtitle={"Updated 3 mins ago"}
              hideEyeIcon={true}
            />
            <BalanceCard
              title={"Ticket sales"}
              figure={`$${formatMoney(game?.ticketSales)}`}
              subtitle={"Total gains 0%"}
              hideEyeIcon={true}
            />
          </div>

          {/* ticket & messages */}
          <div className={lotteryStyles.ticketMessageFlex}>
            {/* ticket sales */}
            <div className={lotteryStyles.ticket}>
              <div className="flexRow justifyBetween">
                <p className={lotteryStyles.asideTitle}>Ticket sales</p>
                <p
                  className={`flexRow alignCenter cursor-pointer fs14`}
                  onClick={() => setIsTicketModalOpen(true)}
                >
                  View all <IoChevronForward fontSize={"20px"} />
                </p>
              </div>
              <div className={`${lotteryStyles.box}`}>
                <div className={lotteryStyles.ticketContainer}>
                  <table>
                    <thead>
                      <tr>
                        <td>ID</td>
                        <td>Lottery no</td>
                      </tr>
                    </thead>

                    <tbody>
                      {gameTickets?.tickets?.slice(0, 5)?.map((ticket) => {
                        let date = DateTime.fromSeconds(
                          ticket?.createdAt?._seconds
                        );
                        date = date.toRelativeCalendar();

                        const ticketSelections = JSON.parse(ticket?.selection);
                        const selection1 = ticketSelections.length
                          ? ticketSelections[0]
                          : [];

                        return (
                          <tr key={ticket?.id}>
                            <td className={lotteryStyles.st1}>
                              {truncateText(ticket?.id, 5)}
                            </td>
                            <td className={lotteryStyles.st2}>
                              <span className={lotteryStyles.tablePill}>
                                {selection1.map((val) => `${val} `)}
                              </span>
                            </td>
                            <td className={lotteryStyles.st3}>{date}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Loader
                    isLoading={isTicketsLoading}
                    itemLength={gameTickets?.tickets?.length}
                    isNullText={"No tickets yets"}
                    height={"100%"}
                    variety={"dark"}
                  />
                  {!isTicketsLoading && !gameTickets?.tickets?.length ? (
                    <div
                      className="flexColumn justifyCenter alignCenter text-muted textCenter fs14"
                      style={{ height: "120px" }}
                    >
                      No purchased tickets yet
                      <br /> yet
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* messages */}
            <Box width={["full", "50%"]}>
              <Flex justify={"space-between"} align={"center"}>
                <Text fontSize={"14px"} fontWeight={500}>
                  Messages
                </Text>
              </Flex>

              <Box
                boxSizing={"border-box"}
                width={"full"}
                bg={"#fff"}
                rounded={"16px"}
                boxShadow={"0 4px 16px 0 #0000000f"}
                padding={"16px 0"}
                height={"216px"}
                mt={"16px"}
                className={"vScroll"}
                overflowX={"hidden"}
              >
                <Flex
                  justify={"flex-end"}
                  align={"center"}
                  gap={"15px"}
                  px={"24px"}
                  mb={2}
                >
                  <Image src={MsgAdd} boxSize={"24px"} alt="" />
                  <Icon as={FaEllipsisH} color={"#48494C"} />
                </Flex>
                <Loader
                  isLoading={isGameNotificationsLoading}
                  height={"80px"}
                />
                <Box px={"10px"}>
                  {gameNotifications?.map((val) => (
                    <MsgNotificationItem key={val?.id} item={val} />
                  ))}
                </Box>
                {!isGameNotificationsLoading && !gameNotifications?.length ? (
                  <Flex flexDir={"column"} gap={"16px"} mt={"16px"}>
                    <Text
                      textAlign={"center"}
                      fontSize={"14px"}
                      color={"#48494C"}
                      w={"90%"}
                      mx={"auto"}
                    >
                      Sorry you don’t have any active conversations at the
                      moment.
                    </Text>
                    <Button
                      colorScheme={"buttonPrimary"}
                      w={"80%"}
                      mx={"auto"}
                      fontSize={"14px"}
                      fontWeight={400}
                    >
                      Start conversation
                    </Button>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </div>

          {/* leaderborad */}
          <div className={lotteryStyles.leaderboard}>
            <div className={`flexRow justifyBetween ${lotteryStyles.mb12}`}>
              <p className={lotteryStyles.asideTitle}>Leaderboard</p>
              {/* <p
                className="flexRow alignCenter cursor-pointer fs14"
                onClick={() => setIsLeaderboardModalOpen(true)}
              >
                View all <IoChevronForward fontSize={"20px"} />
              </p> */}
            </div>
            <div
              className={lotteryStyles.leaderboardContainer}
              style={{ maxHeight: "361px", overflowY: "auto" }}
            >
              <div className={`${lotteryStyles.tableResponsive} hScroll`}>
                <table>
                  <thead>
                    <tr>
                      <td>Player name</td>
                      <td>Rank</td>
                      <td>Lottery no</td>
                      <td>Jackpot win</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedLeaderboards?.map((val, idx) => (
                      <LBRow
                        key={`ld-${idx}`}
                        val={val}
                        uid={user?.uid || ""}
                        winningNumbers={game?.blockResult || []}
                      />
                    ))}
                  </tbody>
                </table>
                <Loader
                  isLoading={isLeaderboardLoading}
                  itemLength={leaderboard?.leaderboards?.length}
                  // isNullText={"No tickets yets"}
                  height={"100%"}
                />
                {!isLeaderboardLoading && !leaderboard?.leaderboards?.length ? (
                  <div
                    className="flexColumn justifyCenter alignCenter text-muted textCenter fs14"
                    style={{ height: "120px", marginBlock: "40px" }}
                  >
                    Leaderboard is currently empty.
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </aside>

        {/* modals */}
        <Modal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        >
          <div className="flexRow justifyCenter">
            <img src={WasteCollection} className="deleteIllustration" alt="" />
          </div>
          <h3 className="deleteTitle">Delete Lottery</h3>
          <p className="deleteSubtitle">
            Are you sure you want to delete this {game?.status} lottery?
          </p>
          <div className="flexRow gap-1 mt35">
            <CustomButtonII
              variant="light"
              text={"Don't delete"}
              className={"w50"}
              centerText={true}
              onClick={() => setShowDeleteModal(false)}
            />
            <CustomButtonII
              variant="ghost-danger"
              text={"Yes, delete"}
              className={"w50"}
              centerText={true}
              loading={isDeleteGameLoading}
              onClick={handleDeleteGame}
            />
          </div>
        </Modal>

        <TicketSalesModal
          isOpen={isTicketModalOpen}
          onClose={() => setIsTicketModalOpen(false)}
          tickets={gameTickets?.tickets}
        />
      </section>
    </>
  );
};

const LBRow = ({ val, winningNumbers }) => {
  const [isSelectionsModalOpen, setIsSelectionsModalOpen] = useState(false);
  const { formatMoney } = useTextTruncate();

  let date = DateTime.fromSeconds(val?.ticket?.createdAt?._seconds);
  // @ts-ignore
  date = date.toRelativeCalendar();

  const results = val?.results;
  const selections = results?.length ? results[0]?.selection : [];

  const allSelections = [];
  const matches = [];
  results?.forEach((val) => {
    allSelections.push(val?.selection);
    matches.push(val?.matches);
  });

  const selectionString = selections?.map((val) => val + " ");

  return (
    <>
      <tr
        onClick={() => setIsSelectionsModalOpen(true)}
        style={{ cursor: "pointer" }}
      >
        <td>
          <div className={`flexRow alignCenter`}>
            <Avatar
              fontSize="20px"
              name={val?.user?.username}
              className={lotteryStyles.tableAvatar}
              onlyFirstLetter={true}
              src={val?.user?.profileUrl}
              boxSize={"40px"}
            />
            <div>
              <p className={lotteryStyles.leaderName}>{val?.user?.username}</p>
            </div>
          </div>
        </td>
        <td>
          {Number.isInteger(val?.rank) && val?.rank < 4
            ? Ranks[val?.rank - 1]
            : "-"}
        </td>
        <td>
          <p className={lotteryStyles.leaderLottoNumber}>{selectionString}</p>
        </td>
        <td>${formatMoney(val?.prize)}</td>
        <td>{date}</td>
      </tr>
      <div style={{ color: "#000" }}>
        <SelectionModal
          allSelections={allSelections}
          isOpen={isSelectionsModalOpen}
          onClose={() => setIsSelectionsModalOpen(false)}
          winningNumbers={winningNumbers}
          matches={matches}
        />
      </div>
    </>
  );
};

const MsgNotificationItem = ({ item }) => {
  const dateTime = DateTime.fromISO(item?.time);
  // @ts-ignore
  const date = dateTime.toRelativeCalendar();

  const type = item?.type?.replace(/_/g, " ")?.replace(/-/g, " ");
  return (
    <Popover>
      <PopoverTrigger>
        <Flex
          justify={"space-between"}
          cursor={"pointer"}
          py={"10px"}
          px={"5px"}
          rounded={"8px"}
          align={"center"}
          gap={1}
          _hover={{
            bg: "#EDEFF1",
          }}
        >
          <Flex
            boxSize={"36px"}
            bg={"brand.secondary"}
            color={"brand.black"}
            rounded={"50%"}
            justify={"center"}
            align={"center"}
          >
            <Icon as={IoIosNotifications} fontSize={"22px"} />
          </Flex>
          <Flex
            flexDir={"column"}
            justify={"center"}
            width={"calc(100% - 36px)"}
          >
            <Flex justify={"space-between"}>
              <Text
                textTransform={"capitalize"}
                fontSize={"12px"}
                fontWeight={600}
              >
                {truncateText(type, 12)}
              </Text>
              <Text
                fontSize={"12px"}
                textAlign={"right"}
                color={"brand.textMuted"}
              >
                {date}
              </Text>
            </Flex>
            <Text color={"brand.textMuted"} fontSize={"12px"} fontWeight={500}>
              {truncateText(item?.message, 20)}
            </Text>
          </Flex>
        </Flex>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader
          fontSize={"14px"}
          textTransform={"capitalize"}
          fontWeight={500}
        >
          {type}
        </PopoverHeader>
        <PopoverBody fontSize={"14px"}>{item?.message}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ViewGame;
