import React, { useEffect, useRef, useState } from "react";
import Text from "../../utils/CustomText";
import ContactCard from "../../components/dashboard/cards/ContactCard";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  // useFetchProfileIIQuery,
  useFetchProfileQuery,
  useUpdateProfileMutation,
} from "../../redux/services/accountApi";
import { showError, showSuccess } from "../../utils/Alert";
import { checkEmptyFields } from "../../utils/Helpers";
import Avatar from "../../utils/Avatar";
import CustomDropdown from "../../utils/CustomDropdown";
import CustomButtonII from "../../utils/CustomButtonII";
// import "../../assets/styles/settings.css";

const EditProfile = () => {
  const navigate = useNavigate();
  const fileElement = useRef(null);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState("");
  const { data: user } = useFetchProfileQuery();
  // console.log(user);
  const [formState, setFormState] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
    photoUrl: "",
  });

  const goBack = () => {
    navigate(-1);
  };

  const handleInputs = (e) => {
    const { name, value } = e.target;

    setFormState({ ...formState, [name]: value });
  };

  const fileOnChange = (e) => {
    let images = e.target.files;

    const allowedExtensions = /(\.jpeg|\.jpg|\.png)$/i;
    if (!allowedExtensions.exec(e.target.value)) {
      showError("Invalid file type");
      return false;
    }

    const TwoMB = 2097152;
    if (images?.length) {
      if (images[0].size >= TwoMB) {
        showError("File must be less than 2MB");
        return;
      } else {
        setFile(images?.length ? images[0] : undefined);
      }
    }
  };

  useEffect(() => {
    if (!file) {
      setPreview("");
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const clickFileElem = () => {
    if (fileElement.current) {
      fileElement.current.click();
    }
  };

  const [updateProfile, { isLoading: isUpdateProfileLoading }] =
    useUpdateProfileMutation();
  const submitForm = (e) => {
    e.preventDefault();
    const { firstName, middleName, lastName, gender, dob } = formState;
    const isEmpty = checkEmptyFields({
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      gender,
      date_of_birth: dob,
    });

    if (!isEmpty) {
      updateProfile({
        firstName,
        middleName,
        lastName,
        gender,
        dob,
        uid: user?.uid || "",
      })
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            showSuccess("Profile update successful");
          } else {
            console.log(resp);
            showError("Something went wrong, try again later");
          }
        })
        .catch((err) => {
          console.log(err);
          showError(err?.data?.message || err?.message || "An error occurred");
        });
    }
  };

  return (
    <>
      <section className="mainContainer">
        <div className="content">
          <div className="settingHeader">
            <IoIosArrowRoundBack
              size={34}
              className={"arrow-back"}
              onClick={goBack}
            />
            <div className="headerTitle text-center">Edit Profile</div>
          </div>
          <div className="flexColumn alignCenter avatarProfileContainer">
            <Avatar
              name={user?.name}
              src={user?.picture || preview}
              boxSize={"115px"}
            />
            <input
              type="file"
              ref={fileElement}
              onChange={fileOnChange}
              style={{ display: "none" }}
            />
            <button className="uploadBtn" onClick={clickFileElem}>
              Change profile photo
            </button>
            <p className="text-center text-muted uploadText">
              At least 800 x 800 px recommended. <br /> JPG or PNG allowed
            </p>
          </div>
          <form className="profileForm" onSubmit={submitForm}>
            <div className="flexRow">
              <div className="inputContainer">
                <label>First Name</label>
                <input
                  type="text"
                  className="formInput"
                  value={formState.firstName}
                  name="firstName"
                  onChange={handleInputs}
                />
              </div>
              <div className="inputContainer">
                <label>Middle Name</label>
                <input
                  type="text"
                  className="formInput"
                  value={formState.middleName}
                  name="middleName"
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div className="inputContainer">
              <label>Last Name</label>
              <input
                type="text"
                className="formInput"
                value={formState.lastName}
                name="lastName"
                onChange={handleInputs}
              />
            </div>
            <div className="inputContainer">
              <label>Email address</label>
              <input
                type="email"
                className="formInput"
                value={user?.email}
                disabled
              />
            </div>
            <div className="inputContainer">
              <label>Date of Birth</label>
              <input
                type="date"
                className="formInput"
                value={formState.dob}
                name="dob"
                onChange={handleInputs}
              />
            </div>
            <div className="inputContainer">
              <label>Date of Birth</label>
              <CustomDropdown
                value={formState.gender}
                dropdownItems={[
                  { name: "Male", value: "male" },
                  { name: "Female", value: "female" },
                ]}
                itemOnClick={(val) =>
                  setFormState({ ...formState, gender: val })
                }
              />
            </div>

            <div className="text-end mt35">
              <CustomButtonII
                variant={"primary"}
                text={"Update"}
                centerText={true}
                className="btnLg"
                type={"submit"}
                loading={isUpdateProfileLoading}
              />
            </div>
          </form>
        </div>

        {/* aside */}
        <aside className={"asideViewContainer"}>
          {/* <CardSlider /> */}
          <div className={"contactCornerContainer"}>
            <Text tag={"p"} className={"f16 satoshi-bold-text"}>
              Customer corner
            </Text>
            <ContactCard />
          </div>
        </aside>
      </section>
    </>
  );
};

export default EditProfile;
