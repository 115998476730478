import React from "react";
import NotificationStyles from "../../../assets/styles/notification.module.css";
import AngleRight from "../../../assets/images/icons/angle.png";

const TimelineItem = ({ iconBgColor, icon, time, message }) => {
  return (
    <>
      <div className={`${NotificationStyles.container}`}>
        <img src={AngleRight} className={NotificationStyles.arrow} alt="" />
        <div className={`${NotificationStyles.content}`}>
          <div className="flexRow alignCenter">
            <div
              className={`${NotificationStyles.icon}`}
              style={{ background: iconBgColor }}
            >
              <img src={icon} alt="icon" />
            </div>
            <div className="flexColumn justifyCenter">
              <p
                className={`mediumText capitalize ${NotificationStyles.title}`}
              >
                {message}
              </p>
            </div>
          </div>
          <div
            className={`flexColumn alignEnd mediumText text-muted text-end ${NotificationStyles.date}`}
            style={{ gap: "4px" }}
          >
            <p>{time}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimelineItem;
