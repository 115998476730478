// import logo from './logo.svg';
// import SignUp from './pages/auth/SignUp';
import { ColorModeScript } from "@chakra-ui/react";
import RoutesContainer from "./routes/Routes";
import ScrollToTop from "./utils/ScrollToTop";
import theme from "./theme/index";

function App() {
  return (
    <ScrollToTop>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <RoutesContainer />
    </ScrollToTop>
  );
}

export default App;
