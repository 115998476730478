import { showError } from "./Alert";

export const RE_DIGIT = new RegExp(/^\d+$/);

export const setToken = async (data) => {
  try {
    const accessToken = data;
    console.log(accessToken, "token");
    localStorage.setItem("axxToken", accessToken);
  } catch (error) {
    console.log("Could not set accessToken ", error.message);
  }
};

export const shortenXterLength = (string, number = 10) => {
  if (string) {
    if (string.length <= number) {
      return string;
    }
    return `${string.slice(0, number)}..`;
  }
  return "";
};

export const setFlow = async (data) => {
  try {
    localStorage.setItem("flow", data);
  } catch (error) {
    console.log("Could not set flow", error.message);
  }
};

export const retrieveFlow = () => {
  try {
    const data = localStorage.getItem("flow");
    return data;
  } catch (error) {
    console.log("Could not retrieve flow ", error.message);
    return null; // Handle the error as needed
  }
};

export const handleFirebaseError = (firebaseError) => {
  if (firebaseError.code && firebaseError.message) {
    const errorMessage = firebaseError.message;

    // Check if the message starts with the expected prefix
    if (errorMessage.startsWith("Firebase: Error (")) {
      // Extract the part after the prefix
      const startIndex = "Firebase: Error (".length;
      const endIndex = errorMessage.indexOf(")");
      showError(errorMessage.substring(startIndex, endIndex));
    } else {
      showError(errorMessage);
    }
  } else {
    showError("An unexpected error occurred.");
  }
};

const capitalizeFirstText = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeText = (str) => {
  if (str === undefined) {
    return "";
  }

  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const checkEmptyFields = (fields) => {
  let err = false;
  Object.keys(fields).forEach((key) => {
    if (
      fields[key] === "" ||
      fields[key] === undefined ||
      fields[key] === null
    ) {
      showError(capitalizeFirstText(`${key?.replace(/_/g, " ")} is required`));
      err = true;
    }
  });

  return err;
};

export const truncateText = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return text;
  } else {
    return text?.substring(0, maxLength) + "...";
  }
};