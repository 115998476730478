import React from "react";
import Modal from "../../../utils/Modal";

const SelectionModal = ({
  isOpen,
  onClose,
  allSelections,
  winningNumbers,
  matches,
}) => {
  return (
    <Modal title={"Ticket selection"} isOpen={isOpen} onClose={onClose}>
      <div>
        {allSelections?.map((numArr, idx) => (
          <>
            <div className="selection-container" key={`tkNum-${idx}`}>
              {numArr?.map((num, idxx) => (
                <div
                  className="selection-ball"
                  style={{
                    background: winningNumbers?.includes(num)
                      ? "#06C167"
                      : "#FFD600",
                  }}
                  key={`tkNu-${idx}-${idxx}`}
                >
                  {num}
                </div>
              ))}
            </div>
            <p className="selection-text">Matches: {matches[idx]}</p>
          </>
        ))}
      </div>
    </Modal>
  );
};

export default SelectionModal;
